<template lang="pug">
.FcwMobileAppsCarousel
  ClientOnly
    template(v-if="carouselItems.length")
      Carousel.FcwMobileAppsCarousel__carouselContent(
        v-model="selectedItemIndex"
        pause-autoplay-on-hover
        :autoplay="isMultipleItems ? 5000 : 0"
        wrap-around
        :transition="600"
      )
        Slide.FcwMobileAppsCarousel__carouselItem(
          v-for="(item, index) in carouselItems"
          :key="index"
        )
          .FcwMobileAppsCarousel__carouselImage(v-if="item.image.src")
            FImage(
              v-bind="item.image"
              loading="eager"
              fit="contain"
              height="100%"
              width="100%"
              corners="sharp"
            )
          h4 {{ item.text }}

        template(#addons)
          template(v-if="isMultipleItems")
            Navigation.FcwMobileAppsCarousel__carouselNav
            .FcwMobileAppsCarousel__carouselDots
              .FcwMobileAppsCarousel__carouselDots__item(
                v-for="(item, index) in carouselItems"
                :key="item.text"
                :class="{ 'FcwMobileAppsCarousel__carouselDots__item--selected': selectedItemIndex === index }"
                @click="onCarouselChange(index)"
              )

      .FcwMobileAppsCarousel__storeButtons
        FcwMobileAppStoreButtons(
          :mode="smAndDown('dynamic', 'static')"
          :service-type="selectedServiceType"
          :dynamic-link-text="t('common__download_app')"
          @open-android="trackAppStoreButtonTrigger('android')"
          @open-ios="trackAppStoreButtonTrigger('ios')"
        )
</template>

<style lang="stylus">
.FcwMobileAppsCarousel
  padding rem(88) rem(40)
  display flex
  flex-direction column
  justify-content center
  align-items center
  position relative
  height 100%
  width 100%

  +media-down('sm')
    padding rem(40)

  +media-down('xs')
    padding rem(24)

.FcwMobileAppsCarousel__carouselSwitch
  position absolute
  top rem(24)

  +media-down('sm')
    position inherit
    top 0

.FcwMobileAppsCarousel__carouselContent
  color var(--color--neutral--light-5)
  width 100%

  h4
    max-width rem(552)
    text-align center

.FcwMobileAppsCarousel__carouselItem
  flex-direction column

  h4
    -webkit-line-clamp 3
    display -webkit-box
    -webkit-box-orient vertical
    margin-top rem(24)

  +media-down('xs')
    h4
      use-font('heading-5')

.FcwMobileAppsCarousel__carouselImage
  height 50vh

  +media-down('xs')
    width 40vw
    height 70vw

.FcwMobileAppsCarousel__carouselNav
  color var(--color--neutral--light-5)
  transition all 0.4s var(--transition--ease-out)

  &:hover
    color var(--color--primary--light-2)

.FcwMobileAppsCarousel__carouselDots
  margin rem(24) 0
  display flex
  justify-content center
  gap rem(8)
  position relative

.FcwMobileAppsCarousel__carouselDots__item
  width rem(8)
  height rem(8)
  border-radius rem(8)
  background-color var(--color--primary--light-1)
  transition all 0.4s var(--transition--ease-out)
  cursor pointer

  &--selected
    width rem(16)
    background-color var(--color--primary)
    border-radius rem(8)

.FcwMobileAppsCarousel__storeButtons
  margin-top rem(16)
  display flex
  justify-content center
  gap rem(40)

.FcwAppStoresFallback__tabletPortrait
  position absolute
  display flex
  flex-direction column
  padding rem(120)
  text-align center
  justify-content center
  align-items center
  height 100vh
  width 100vw
  color var(--color--neutral--light-5)
  gap rem(24)
  overflow hidden
</style>

<script setup lang="ts">
import { Carousel, Navigation, Slide } from 'vue3-carousel';

import { ServiceType } from '@/models/enums';

import 'vue3-carousel/dist/carousel.css';

export interface FcwMobileAppsCarouselProps {
  /**
   * Carousel items
   */
  carouselItems: MobileAppCarouselItem[];
  /**
   * Service type to display
   * @model
   */
  modelValue: ServiceType;
}

const props = defineProps<FcwMobileAppsCarouselProps>();

const { t } = useClientI18n();

const { smAndDown } = useFBreakpoints();
const { track } = useUserTracking();

const selectedServiceType = useVModelProxy({ props });

const isMultipleItems = computed(() => props.carouselItems.length > 1);

const selectedItemIndex = ref(0);

function onCarouselChange(index: number): void {
  selectedItemIndex.value = index;
}
watch(selectedServiceType, () => onCarouselChange(0));

function trackAppStoreButtonTrigger(type: 'android' | 'ios'): void {
  track.triggerAnAppStoreButton({
    mobileAppName: `${selectedServiceType.value}_app_${type}`,
  });
}
</script>
